import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { SendError } from "./error-log-service";

export const validateJwtToken = (tokenName: string = "token") => {
  if (typeof window !== `undefined`) {
    if (getJwtToken(tokenName) === null) {
      window.location.href = "/401";
      return false;
    }
  }
  return true;
};

const decodeToken = (tokenName: string): any => {
  if (typeof window === `undefined`) return null;
  const token = getJwtToken(tokenName);
  if (token === null || token === "undefined") {
    window.location.href = "/401";
  }

  let decoded = "";

  try {
    decoded = jwt_decode(token);
  } catch (error) {
    SendError(error);
  }

  if (decoded === undefined || decoded === null) {
    window.location.href = "/401";
  }
  return decoded;
};

export const getJwtTokenIdentifier = (tokenName: string = "token") => {
  const nameidentifier =
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier";
  const decoded = decodeToken(tokenName);

  if (decoded != null) {
    return parseInt(decoded[nameidentifier]);
  }
};

export const getJwtTokenRoles = (tokenName: string = "token") => {
  const roleKey =
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
  const decoded = decodeToken(tokenName);

  if (decoded != null) {
    var tokenRoles = decoded[roleKey];

    if (Array.isArray(tokenRoles)) return tokenRoles;

    return [tokenRoles];
  } else {
    return [];
  }
};

export const getJwtToken = (tokenName: string = "token") => {
  const token = Cookies.get(tokenName);
  if (token === undefined || token === null || token === "") {
    return null;
  }
  return token;
};

export const removeJwtToken = (tokenName: string) => {
  if (typeof window === `undefined`) return null;
  Cookies.remove(tokenName);
};

export const removeJwtTokens = (location = "/401") => {
  if (typeof window === `undefined`) return null;
  Cookies.remove("refresh-token");
  Cookies.remove("guest-token");
  Cookies.remove("token", { path: "/" });
  window.location.href = location;
};

export const setJwtTokens = (
  tokenName: string,
  token: string,
  expiresInMinutes: number
) => {
  Cookies.set(tokenName, token, {
    expires: expiresInMinutes / 1440,
    path: "/",
  }); // 30 mins
};

export const getCookie = (name: string) => {
  const cookie = Cookies.get(name);
  if (cookie === undefined || cookie === null || cookie === "") {
    return null;
  }
  return cookie;
};

export const setCookie = (
  name: string,
  value: string,
  expires: number,
  path = "/"
) => {
  Cookies.set(name, value, { expires: expires, path: path });
};
