export default {
	breakpoints: ['768px', '1024px', '1200px', '1920px'],
	colors: {
		black: '#000000',
		primary: '#292929',
		goldenYellow: '#ffc107',
		greenApple: '#639961',
		text: '#5A5A5A',
		menu: '#757575',
		darkgray: '#999999',
		muted: '#E6E6E6',
		lightgray: '#F3F3F3',
		lightergray: '#F9F9F9',
		background: '#FFFFFF',
		white: '#ffffff',
		facebook: '#3b5998',
		twitter: '#0084b4',
		youtube: '#c4302b',
		github: '#211F1F',
		instagram: '#cd486b',
		linkedin: '#0e76a8',
		tiktok: '#EE1D52',
		badge: '#FF5A5F'
	},
	fonts: {
		body: 'Open Sans, sans-serif',
		heading: 'Poppins, serif',
	},
	fontSizes: [13, 15, 19, 20, 30],
	lineHeights: {
		body: 'initial',
		heading: 'initial',
	},
	fontWeights: {
		body: 400,
		heading: 700,
	},
	sizes: {
		container: '1240px',
	},
	buttons: {
		primary: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			cursor: 'pointer',
			minWidth: [130, 172],
			minHeight: [40, 50],
			borderRadius: 6,
			fontWeight: 700,
			textTransform: 'capitalize',
			transition: 'opacity 0.2s ease',
			svg: {
				width: [16, 18],
				height: [16, 18],
				marginLeft: 10,
			},
			':focus': {
				outline: 'none',
			},
			':hover': {
				opacity: 0.9,
			},
		},
		white: {
			color: 'primary',
			backgroundColor: 'white',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			cursor: 'pointer',
			minWidth: [130, 172],
			minHeight: [40, 50],
			borderRadius: 6,
			fontWeight: 700,
			textTransform: 'capitalize',
			transition: 'all 0.2s ease',
			svg: {
				width: [16, 18],
				height: [16, 18],
				marginLeft: 10,
			},
			':focus': {
				outline: 'none',
			},
			':hover': {
				backgroundColor: 'lightgray',
			},
		},
		badge: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			cursor: 'pointer',
			borderRadius: 6,
			minWidth: 38,
			minHeight: 28,
			backgroundColor: 'lightgray',
			padding: 0,
			transition: 'background-color 0.2s ease',
			':focus': {
				outline: 'none',
			},
			':hover': {
				backgroundColor: 'muted',
			},
		},
		text: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			cursor: 'pointer',
			borderRadius: 6,
			minWidth: 48,
			minHeight: 48,
			color: 'primary',
			backgroundColor: 'transparent',
			padding: 0,
			svg: {
				width: 30,
				height: 30,
			},
			':focus': {
				outline: 'none',
			},
		},
	},
	layout: {
		container: {
			paddingX: '20px',
		},
	},
	notice: {
		modes: {
			danger: {
				background: '#8b0000 !important',
				borderLeft: '10px solid #6f0000 !important'
			},
			warning: {
				background: '#ffc107 !important',
				borderLeft: '10px solid #CCA42E !important'
			}
		},
		background: '#3d9cd2',
		display: 'inline-block',
		borderLeft: '10px solid #3485B3',
		borderRadius: '4px',
		color: 'white',
		width: '100%',
		title: {
			fontSize: '14px',
			padding: '10px',
			margin: '0',
			float: 'left !important'
		},
		item: {
			fontSize: '13px',
		},
		icon: {
			marginTop: '-5px',
			marginRight: '10px',
			fontSize: '18px'
		}
	},
	productLabel: {
		modes: {
			danger: {
				background: '#8b0000 !important'
			},
			warning: {
				background: '#ffc107 !important'
			}
		},
		color: 'white',
		position: 'absolute',
		top: '5%',
		right: '5%',
		padding: '6px 18px',
		background: '#3d9cd2',
		zIndex: 1,
		borderRadius: '12px',
		'@media only screen and (max-width: 992px)': {
			top: '10%',
		}
	}
};
