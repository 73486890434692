import axios from "axios";
import {
  getJwtToken,
  getJwtTokenIdentifier,
  removeJwtTokens,
} from "./src/services/auth-service";
import { Refresh } from "./src/services/token-service";
import { SendError, SendErrorMessage } from "./src/services/error-log-service";
import { navigate } from "gatsby";

window.onerror = function (message, source, lineno, colno) {
    SendErrorMessage(
    `${message}, Source: ${source}, Line Number: ${lineno}, Column Number: ${colno}, url: ${window.location}`
  );
  return true;
}

axios.defaults.baseURL = process.env.GATSBY_OUTIBLE_API_URL;
axios.defaults.headers.post = { "Access-Control-Allow-Origin": "*" };

axios.interceptors.request.use(
  function (request) {
    let token = getJwtToken() ?? getJwtToken("guest-token");
    if (token !== null) {
      request.headers["Authorization"] = "Bearer " + token;
    }
    return request;
  },
  async function (error) {
    await SendError(error);
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    await SendError(error);
    if (error.response === undefined) {
      error.response = {};
    }
    
    if (error.response.status === 404) {
      if (error.config && error.config.baseURL === process.env.GATSBY_OUTIBLE_API_URL) {
        alert("There was a problem, please try again later")
      }
      else {
        if (typeof window !== `undefined`) {
          navigate("/404/")
        }
      }
      return Promise.reject(error);
    }

    var isTokenUrl = (error?.config?.url && error.config.url.indexOf("token/refresh") !== -1);
    if (error.response.status === 401 && error.response.headers["token-expired"] === "true" && !isTokenUrl) {
      await tokenRefresh();
      const token = getJwtToken() ?? getJwtToken("guest-token");
      error.config.headers["Authorization"] = "Bearer " + token;
      return await axios.request(error.config);
    } 
    else if (error.response.status === 401 && window.location.pathname.toLowerCase() !== "/login") 
    {
      removeJwtTokens();
      return Promise.reject(error);
    }
    else if (error.response.status === 401  && window.location.pathname.toLowerCase() === "/login") 
    {
      return Promise.reject(error);
    }

    if (error.response.status === 400) {
      return Promise.reject(error);
    }

    // any other api reponse
    error.response.data = {
      message: "Something went wrong, please try again.",
    };
    return Promise.reject(error);
  }
);

async function tokenRefresh() {
  const response = await Refresh({
    userId: getJwtTokenIdentifier(),
    jwtToken: getJwtToken(),
    refreshToken: getJwtToken("refresh-token"),
  });
  if (response.status !== 200) return response; // failed to refresh so return original 401 response
}
