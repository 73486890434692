// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-401-tsx": () => import("./../../../src/pages/401.tsx" /* webpackChunkName: "component---src-pages-401-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-details-tsx": () => import("./../../../src/pages/account/details.tsx" /* webpackChunkName: "component---src-pages-account-details-tsx" */),
  "component---src-pages-account-email-templates-tsx": () => import("./../../../src/pages/account/email-templates.tsx" /* webpackChunkName: "component---src-pages-account-email-templates-tsx" */),
  "component---src-pages-account-email-tsx": () => import("./../../../src/pages/account/email.tsx" /* webpackChunkName: "component---src-pages-account-email-tsx" */),
  "component---src-pages-account-orders-tsx": () => import("./../../../src/pages/account/orders.tsx" /* webpackChunkName: "component---src-pages-account-orders-tsx" */),
  "component---src-pages-account-password-tsx": () => import("./../../../src/pages/account/password.tsx" /* webpackChunkName: "component---src-pages-account-password-tsx" */),
  "component---src-pages-account-reports-courier-vat-tsx": () => import("./../../../src/pages/account/reports/courier-vat.tsx" /* webpackChunkName: "component---src-pages-account-reports-courier-vat-tsx" */),
  "component---src-pages-account-reports-product-vat-tsx": () => import("./../../../src/pages/account/reports/product-vat.tsx" /* webpackChunkName: "component---src-pages-account-reports-product-vat-tsx" */),
  "component---src-pages-account-reports-tsx": () => import("./../../../src/pages/account/reports.tsx" /* webpackChunkName: "component---src-pages-account-reports-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-account-user-activity-tsx": () => import("./../../../src/pages/account/user-activity.tsx" /* webpackChunkName: "component---src-pages-account-user-activity-tsx" */),
  "component---src-pages-account-user-management-tsx": () => import("./../../../src/pages/account/user-management.tsx" /* webpackChunkName: "component---src-pages-account-user-management-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

