import React, { Fragment } from "react";
import { ThemeProvider } from "theme-ui";
import { Global } from "@emotion/react";
import { Helmet } from "react-helmet";
import SearchProvider from "../../provider/search-provider";
import styles from "./layout.style";

const Layout: React.FunctionComponent = ({ children }) => {
  return (
    <SearchProvider>
      <Fragment>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1 maximum-scale=1"
          />
        </Helmet>
        <Global styles={styles} />
        {children}
      </Fragment>
    </SearchProvider>
  );
};

export default Layout;
