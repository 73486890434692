const styles = {
  body: {
    margin: "0",
    fontFamily: '"Organey-heading", sans-serif',
    fontSize: "13px",
    WebkitTapHighlightColor: "transparent",
    WebkitFontSmoothing: "antialiased",
    color: "#685f52",
  },
  p: {
    lineHeight: 2,
  },
  a: {
    color: "#685f52",
  },
  "@media only screen and (min-width: 768px)": {
    body: {
      fontSize: "15px",
    },
    p: {
      lineHeight: "1.4",
    },
  },
  ".drawer:focus": {
    outline: "none",
  },
  ".drawer-content-wrapper": {
    maxWidth: "500px",
    footer: {
      display: "none",
    },
  },
  ".drawer-left .drawer-content-wrapper": {
    maxWidth: "unset",
  },
  "@media only screen and (max-width: 480px)": {
    ".drawer-content-wrapper": {
      footer: {
        minHeight: "70px",
        maxWidth: "500px",
        width: "100%",
        position: "absolute",
        paddingTop: "0",
        bottom: "0",
        left: "0",
        zIndex: "1",
        ">div": {
          justifyContent: "center",
        },
        ".copyright": {
          display: "none",
        },
      },
    },
  },
};

export default styles;
